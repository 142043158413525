<template>
    <div ref="containerWrap">
        <slot />
    </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';
export default {
    props: {
        options: {
            type: Object,
            default: () => ({
                items: 1,
                center: true,
                fixedWidth: 177,
                speed: 400,
                loop: false,
                swipeAngle: false,
                nav: true,
                navPosition: 'bottom',
                mouseDrag: true,
                arrowKeys: true,
                navAsThumbnails: true,
                rewind: false,
                startIndex: 1,
            }),
        },
    },
    data: () => ({
        slider: null,
    }),
    mounted() {
        this.initSlider();
    },
    beforeDestroy() {
        this.slider.destroy();
        this.slider = null;
    },
    methods: {
        initSlider() {
            this.slider = tns({ ...this.options, container: this.$refs.containerWrap.children[0] });
            this.slider.events.on('indexChanged', this.onIndexChanged);
        },
        onIndexChanged(event) {
            this.$emit('indexChanged', event.index);
        },
    },
};
</script>
